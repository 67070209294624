import { For, Show, createSignal } from "solid-js";
import type { ExhibitionCardProps } from "../_model";
import LunchIcon from "../assets/Launch 2.svg";
import placeholder_img from "../assets/images/cover_placeholder.png";
import placeholder_person from "../assets/images/person_placeholder.png";
import { global } from ":global";
import { ExploreProject } from "./explore-project";

export function ExhibitionCard(props: ExhibitionCardProps) {
  const handleViewProject = () => {
    global.store.actions.popop.pushModal({
      title: props?.project_name ?? "No project name",
      element: () => <ExploreProject {...props} />,
    });
  };

  return (
    <div class="relative w-full h-auto max-w-[26rem] flex flex-col  rounded-xl bg-white bg-clip-border border-1 border-solid border#border-light">
      <div class="flex-1">
        <Show
          fallback={
            <div class="relative m-0 overflow-hidden text-gray-700 bg-transparent rounded-t-xl  bg-clip-border">
              <img
                class="w-full h-full max-h-8rem object-cover rounded-t-lg object-cover"
                src={placeholder_img}
                alt="alt"
              />
            </div>
          }
          when={props?.project_cover}
        >
          <div class="relative m-0 overflow-hidden text-gray-700 bg-transparent rounded-t-xl  bg-clip-border">
            <img
              class="w-full h-full max-h-8rem object-cover rounded-t-lg object-cover"
              src={props?.project_cover}
              alt="alt"
            />
          </div>
        </Show>

        <div class="w-full flex justify-between items-center gap-3 relative px-2 mt-3 ">
          <div>
            <h5 class="text-black font-400! text-[14px] py-1 md:py-0">{props?.team_name ?? "No team name"}</h5>
            <h3 class="text-black font-bold text-[19px] md:py-2">{props?.project_name ?? "No project name"}</h3>
          </div>
          <p class="text#warn bg#bg-orange text-12px font-600  absolute right-[15px] top-0 px-2 py-[3px] rounded-md">
            {props?.track}
          </p>
        </div>
        <p class="text-[14px] py-2 md:pt-1 md:pb-2 text-black text-start px-2">{props?.ideation ?? "No ideation"}</p>
      </div>
      <div class="flex justify-between items-center py-2 relative px-2">
        <div class=" flex flex-row justify-start items-center">
          <For each={props?.team_members}>
            {(member, index) => {
              const left_shift = 3;
              console.log("left_shift:: ", left_shift);
              return (
                <Show
                  fallback={
                    <img
                      width={44}
                      height={44}
                      class={`-ml-${index() === 0 ? 0 : left_shift} w-44px h-44px rounded-full`}
                      src={placeholder_person}
                      alt={`img${index}`}
                    />
                  }
                  when={member.image}
                >
                  <img
                    width={44}
                    height={44}
                    class={`-ml-${index() === 0 ? 0 : left_shift} w-44px h-44px rounded-full`}
                    src={member?.image}
                    alt={`img${index}`}
                  />
                </Show>
              );
            }}
          </For>
        </div>

        <button
          onClick={handleViewProject}
          class={`text#orange px-0 py-[12px] rounded-md font-bold text-[14px] cursor-pointer flex justify-center items-center gap-2 bg-white `}
        >
          {`View Project`}
          <LunchIcon class="w-25px" />
        </button>
      </div>
    </div>
  );
}
